import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import "../../assets/css/customerlogin.css";
import $ from 'jquery';
import axios from 'axios';
const OtpForm = ({ length = 4, onChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const handleChange = (e, index) => {
    debugger;
    const { value } = e.target;
    if (/^\d*$/.test(value)) { // Allow only digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
     
      
      // Move to the next input field if a digit is entered
      if (value && index < length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
      else{
        sessionStorage.setItem('SessionOTP', otp);
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) prevInput.focus();
    }
  };
  const OtpSubmit = () => {
    debugger;
    const enteredOtp = otp.join(''); // This converts the array to a string without commas
    const formData = new FormData();
    debugger;
    if (enteredOtp != '') {
        let responseloginId=sessionStorage.getItem('Sessionresponseid');
        formData.append('id', responseloginId);
        formData.append('otp', enteredOtp);
        const response = axios.post('https://api.rollerfashions.com/api/Authentication/Customer_OTP', formData, {
            headers: {
                'Content-Type': 'application/json'
            }

        })
            .then(response => {
                debugger;
                var getresponse = response.data;
                if (getresponse.responseCode == 1) {
                sessionStorage.setItem("tokenId",getresponse.responsetoken);//Very Important
                sessionStorage.setItem("userId",getresponse.responseid);//Very Important
                window.location.href='../account';
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
    }  return false;
  };
  return (
    <>
    <div className="otp-form-group">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="otp-input"
        />
      ))}
    </div>
    <br></br>
    <Button variant="primary" onClick={OtpSubmit} className="w-100 btn-primary p-3">
                                    Submit
                                </Button>
  
    </>
  );
};

export default OtpForm;
