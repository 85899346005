import React from "react";
import Header from "./Header";
import ProductDetailCore from "./ProductDetailCore";
import Footer from "./Footer";
import "../../assets/css/home.css";

const ProductDetails = () => {
    return (

        <div className="home-container">
            <Header />
           
             <ProductDetailCore></ProductDetailCore>
            <Footer />
           
        </div>

    )
}

export default ProductDetails;