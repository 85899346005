import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Carousel, Button, NavbarCollapse } from 'react-bootstrap';
import Slider from 'react-slick';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/sale.css';
import Image1 from "../../assets/images/Rectangle 23.png"
import Image2 from "../../assets/images/Rectangle 23 (1).png"
import Image3 from "../../assets/images/Rectangle 23 (2).png"
import Image4 from "../../assets/images/Rectangle 23 (3).png"
import Image9 from "../../assets/images/Group 5.png"
import { FaRegHeart } from "react-icons/fa";
import auth_img from '../../assets/images/bi_bag.svg';

const Popular = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const maxVisibleItems = "4";
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    function handlertoggle(event) {

        var getid = $(event).attr('id');
        $('#dv' + getid).slideToggle();
    }
    useEffect(() => {
        fetchFeatured_Subcat();

    }, []);
    const fetchFeatured_Subcat = async () => {
        fetch('https://api.rollerfashions.com/api/Home/Featured_Product') // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }; // Empt
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            <div className="padding">

                <br>
                </br><br></br>
                <h2 className='top-categories-heading mt-2'><b>FAVOURITIES -</b> <span className="thin">BEST SELLERS</span></h2>


                <div className="product-slider">
                    <Slider {...settings}>
                        {data.map((product) => (
                            <div key={product.id} className="product-item">
                                <a href={'/Product?ProductId=' + product.id}>
                                    <Carousel fade interval={null} className="hover-carousel" >
                                        <Carousel.Item>
                                            <img src={product.display_image} className="d-block w-100" alt={product.name} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={product.sample_image1} className="d-block w-100" alt={product.name} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={product.sample_image2} className="d-block w-100" alt={product.name} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={product.sample_image3} className="d-block w-100" alt={product.name} />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={product.sample_image4} className="d-block w-100" alt={product.name} />
                                        </Carousel.Item>
                                    </Carousel>
                                </a>
                                <label title="Add to wishlist" className="WishListicon"></label>
                                <span className='inline'>
                                    {product.color.map((item, index) => (
                                        index < maxVisibleItems ? (
                                            <Image title={item.color_Name} src={item.color_Image} className="circle"></Image>
                                        ) : null))}
                                    {product.color.length > maxVisibleItems ? (
                                        <a className='additoionalProd' href={'/Product?ProductId=' + product.id}>+ {product.color.length-maxVisibleItems}</a>
                                    ) : null}

                                </span>
                                <h5>{product.name}</h5>
                                <p>&#8377;&nbsp;{product.selling_price} <strike>{product.price}</strike><span className='disc'>{product.discount}%</span></p>
                                <div id={'dv' + product.id} className='sizechart_mini'>

                                    <Container fluid className='sizemini'>

                                        <Row>
                                            <Col sm={2}>
                                                <a href='#'>s</a>
                                            </Col>
                                            <Col sm={2}>
                                                <a href='#'>m</a>
                                            </Col>
                                            <Col sm={2}>
                                                <a href='#'>l</a>
                                            </Col>
                                            <Col sm={2}>
                                                <a href='#'>xl</a>
                                            </Col>
                                            <Col sm={2}>
                                                <a href='#'>xxl</a>
                                            </Col>

                                        </Row>
                                    </Container>
                                </div>
                            </div>

                        ))}
                    </Slider>
                </div>
            </div> </>

    );
}

export default Popular;
