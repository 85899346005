import React from "react";
import Header from "./Header";
import ProductList from "./ProductsList";
import Footer from "./Footer";
import "../../assets/css/home.css";

const AllProducts = () => {
    return (

        <div className="home-container">
            <Header />
           
             <ProductList></ProductList>
            <Footer />
            <div id="backgroundbody">

            </div>
        </div>

    )
}

export default AllProducts;