import React from 'react';
import Header from "../Customer/Header";
import Footer from "./Footer";
const Shipping = () => {
  return (
    <>
    <Header />  <br></br> <br></br>
    <div className='mt-5' style={styles.container}>
     

      {/* Refund and Cancellation Policy */}
   

      {/* Shipping Policy */}
      <section style={styles.section}>
        <h2>Shipping Policy</h2>
        <p>
          The orders for the user are shipped through registered domestic courier companies and/or speed post only. Orders are shipped within 5 days from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable.
        </p>
      </section>
    </div> <br></br>
    <Footer></Footer>
    </>
  );
};

// Basic styling for the component
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  section: {
    marginBottom: '40px',
  },
  divider: {
    margin: '40px 0',
    border: 'none',
    borderTop: '1px solid #ccc',
  },
};

export default Shipping;
