import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/css/profile.css";

import Login from '../Customer/Login';
import loginImage from '../../assets/images/10.png'; // Adjust path as needed
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';

function Address() {
    const [data, setData] = useState();
    const [LocationData, setLocationData] = useState();
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const errors = {};
    const token = sessionStorage.getItem("tokenId");
    const UserId = sessionStorage.getItem("userId");//Very Important
    const [error, setError] = useState();
    const [formData, setFormData] = useState({
        cname: '',
        number: '',
        flat: '',
        address: '',
        pincode: '',
        area: '',
        city: '',
        state: '',
        country: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Step 3: Handle form submission
    const Add_Address = async(e) => {
        debugger;
        e.preventDefault();

        // Validate form data before submitting
        

            if (UserId != "") {
                const addressData = new FormData();
                addressData.append('isprimary', true);
                addressData.append('user_ID', UserId);
                addressData.append('name', formData.cname);
                addressData.append('number', formData.number);
                addressData.append('flat', formData.flat);
                addressData.append('address', formData.address);
                addressData.append('pincode', formData.pincode);
                addressData.append('area', formData.area);
                addressData.append('city', formData.city);
                addressData.append('state', formData.state);
                addressData.append('country', formData.country);
                debugger;
                if (UserId != null) {
                    const response =await axios.post(`https://api.rollerfashions.com/api/Addressbook/Create_addressbook`, formData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                    debugger;
                    setData(response.data);
                   window.location.href="../Address";
                }
              
            }
           
          

            // Reset form fields
            setFormData({
                cname: '',
                number: '',
                flat: '',
                address: '',
                pincode: '',
                area: '',
                city: '',
                state: '',
                country: '',
            });
        

        // Reset form after submission if needed

    };

    // const validateForm = () => {
       
    //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     const phonePattern = /^[0-9]{10}$/;
    //     const pincodePattern = /^[0-9]{6}$/;

    //     if (!formData.cname.trim()) errors.name = 'Name is required';
    //     if (!phonePattern.test(formData.phone)) errors.phone = 'Phone number should be 10 digits';
    //     if (!formData.address.trim()) errors.address = 'Address is required';
    //     if (!pincodePattern.test(formData.pincode)) errors.pincode = 'Pincode should be 6 digits';
    //     debugger;
    //     setError(errors);
    //     return Object.keys(errors).length === 0;
    // };


    

    const popcancel = async () => {
        window.location.href = "../Address";
    }

    const fetchLocationData = async () => {
        debugger;
        if (formData.pincode.length !== 6 || isNaN(formData.pincode)) {
            setError('Please enter a valid 6-digit pincode');
            return;
        }
        setError('');
        try {
            const response = await axios.get(
                `https://api.postalpincode.in/pincode/${formData.pincode}`
            );

            const data = response.data[0];
            if (data.Status === 'Success') {
                const { PostOffice } = data;
                if (PostOffice && PostOffice.length > 0) {
                    // Update formData with area, city, state, and country values
                    setFormData({
                        ...formData,
                        area: PostOffice[0].Name,
                        city: PostOffice[0].District,
                        state: PostOffice[0].State,
                        country: PostOffice[0].Country,
                    });
                }
            } else {
                setError('No data found for this pincode');
                setLocationData(null);
            }
        } catch (err) {
            setError('An error occurred while fetching data');
            setLocationData(null);
        }
    };




    return (
        <>



            <Container>
                <center><h3>NEW ADDRESS</h3></center>
                
                   
                  
                   
                
                <div className="container mt-5">
                    <form onSubmit={Add_Address}>
                        <Row>
                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='cname'
                                    placeholder='Name'
                                    type='text'
                                    required
                                    value={formData.cname}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='number'
                                    placeholder='Phone'
                                    type='text'
                                    required
                                    value={formData.number}
                                    onChange={handleChange}
                                />
                                <h1 className="lbl">{errors.phone}</h1>
                            </Col>

                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='flat'
                                    placeholder='Door No./H-No.'
                                    type='text'
                                    required
                                    value={formData.flat}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <textarea
                                    className='form-control'
                                    name='address'
                                    placeholder='Lane Number and Land Marks'
                                    required
                                    value={formData.address}
                                    onChange={handleChange}
                                ></textarea>
                                  
                            </Col>

                            <Col md={6}>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='pincode'
                                    placeholder='Enter Pincode'
                                    value={formData.pincode}
                                    onChange={handleChange}
                                />
                            </Col>


                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='area'
                                    placeholder='Area'
                                    type='text'
                                    required
                                    value={formData.area}
                                    onChange={handleChange}
                                    onClick={fetchLocationData}
                                />
                            </Col>

                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='city'
                                    type='text'
                                    required
                                    placeholder='City'
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='state'
                                    type='text'
                                    required
                                    placeholder='State'
                                    value={formData.state}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <input
                                    className='form-control'
                                    name='country'
                                    type='text'
                                    required
                                    placeholder='Country'
                                    value={formData.country}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={3}>
                                <Button type="submit" className='btn primarybtn btn-lg'>
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Container>

            {isLoginVisible && (
                <div className='divpop'>
                    <Login></Login>
                </div>
            )}

        </>

    )
}
export default Address;