import React from "react";
import Header from "../Customer/Header";
import Banner from "./banner";
import TopCategories from "./TopCategories";
import Justin from "./JustIn";
import Sale from "./Sale";
import Rate from "./ratesection";
import TrendingNow from "./TrendingNow";
import PopularApparel from "./PopularApparel";
import Middlebanner from "./middlebanner";
import LastBanner from "./LastBanners";
import Footer from "./Footer";

import "../../assets/css/home.css";

const Home = () => {
    return (

        <div className="home-container">

            <Header />
            <Banner />
            <TopCategories />
            <Justin />
            <Middlebanner />
            <Sale />
            <TrendingNow />
            <Rate />
            <PopularApparel />
            <LastBanner />
            <Footer />
            <div id="backgroundbody">
                
            </div>
        </div>

    )
}

export default Home;