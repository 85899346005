import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Components/Customer/Home";
import ProductDetails from "./Components/Customer/ProductDetails";
import AllProducts from './Components/Customer/Allproduct';
import PrivacyPolicy from './Components/Customer/privacypolicy';
import Termsandconditions from './Components/Customer/termsandconditions';
import Shipping from './Components/Customer/shipping';
import Cancellation from './Components/Customer/cancellation';
import Checkout from './Components/Customer/Checkout';
import Account from './Components/Customer/Profile';
import Address from './Components/Customer/Address';
import { Helmet } from "react-helmet";
function App() {
     return (
        <> <Helmet>
        <title>Rollerfashions || Mens Online shopping store||Shirts||Tshirts</title>
    
    </Helmet><Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/AllProducts" element={<AllProducts />} />
                <Route path="/Product" element={<ProductDetails />} /> 
                <Route path="/Checkout" element={<Checkout />} />
                <Route path="/Account" element={<Account />} />
                <Route path="/Address" element={<Address />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/tandc" element={<Termsandconditions/>} />
                <Route path="/shippingPolicy" element={<Shipping/>} />
                <Route path="/ReturnandCancellation" element={<Cancellation/>} />
                </Routes>
        </Router>
       
     </>
    );
}

export default App;
