import React from 'react';
import '../../assets/css/stepProgressbar.css'; // Add custom styles if needed

const StepProgressBar = ({ steps, currentStep }) => {
  return (
    <div className="step-progress-bar">
    {steps.map((step, index) => (
      <div key={index} className="step">
        <div className={`circleprogress ${index <= currentStep ? 'completed' : ''}`}>
          {index + 1}
        </div>
        <div className={`label ${index <= currentStep ? 'completed-label' : ''}`}>
          {step}
        </div>
        {index < steps.length - 1 && (
          <div className={`bar ${index < currentStep ? 'completed-bar' : ''}`}></div>
        )}
      </div>
    ))}
  </div>
  );
};



export default StepProgressBar;