import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/css/profile.css";
import Header from '../Customer/Header';
import Login from '../Customer/Login';
import loginImage from '../../assets/images/10.png'; // Adjust path as needed
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import Footer from "./Footer";
function Profile() {
    const buttonRef = useRef(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isULVisible, setIsULVisible] = useState(false);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const token = sessionStorage.getItem("tokenId");
    const UserId = sessionStorage.getItem("userId");//Very Important
    const [error, setError] = useState(null);
    useEffect(() => {
        debugger;
        fetchAccountDetails();
    }, []);
    const logout = () => {
        sessionStorage.clear();
        window.location.href="./Home";
    }
    const handleToggle = () => {
        setIsULVisible(!isULVisible);
      };
    const fetchAccountDetails = async () => {
        debugger;
        if (UserId != null) {
            const url = `https://api.rollerfashions.com/api/Customer/Get_Profile?User_ID=${UserId}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            debugger;
            setData(response.data);
        }
        else {
            setIsLoginVisible(!isLoginVisible);
        }

    }; // Empty dependency array means this effect runs once on mount

    return (
        <>
            <Header />
            <div className='dvProfile'>
                <Container>
                    <Row>
                        <Col md={12}>
                            Hello !
                            <ul>
                                <li>
                                    <div onClick={handleToggle}><a className='btnProfile'  >Profile    </a> <span>{isULVisible ? '▲' : '▼'}</span></div>
                                    {isULVisible && (
                                    <ul className='fadded'>
                                        <li>
                                            <a href='../Address' className='btnProfile'>Address<span>→</span></a>
                                        </li>
                                        <li>

                                            <a className='btnProfile'> Contact</a><span>{data.phone}</span>
                                        </li>
                                        <li>
                                            <a className='btnProfile'> Email</a><span>{data.email}</span>
                                        </li>

                                    </ul>)}
                                </li>
                                <li>

                                    <a className='btnProfile'> Purchases History</a><span>▼</span>
                                </li>
                                <li>
                                    <a className='btnProfile'> Help</a>
                                </li>
                                <li>
                                    <a onClick={logout} className='btnProfile'> Logout</a>

                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            {isLoginVisible && (
                <div className='divpop'>
                    <Login></Login>
                </div>
            )}
            <Footer />
        </>

    )
}
export default Profile;