import React from 'react';
import Header from "../Customer/Header";
import Footer from "./Footer";
const CancellationPolicy = () => {
  return (
    <>
    <Header />  <br></br> <br></br> <div style={styles.container}>
     

      {/* Refund and Cancellation Policy */}
      <section className='mt-5' style={styles.section}>
        <h2>Refund and Cancellation Policy</h2>
        <p>
          This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy:
        </p>
        <ul>
          <li>
            <strong>Cancellations:</strong> Cancellations will only be considered if the request is made within 15 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
          </li>
          <li>
            <strong>Damaged or Defective Items:</strong> In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/ merchant listed on the Platform has checked and determined the same at its own end. This should be reported within 15 days of receipt of products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 15 days of receiving the product. The customer service team after looking into your complaint will take an appropriate decision.
          </li>
          <li>
            <strong>Warranty Issues:</strong> In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.
          </li>
          <li>
            <strong>Refund Processing:</strong> In case of any refunds approved by M R FURNITURE, it will take 7 days for the refund to be processed to you.
          </li>
        </ul>
      </section>

     

      {/* Return Policy */}
      <section style={styles.section}>
        <h2>Return Policy</h2>
        <p>
          We offer refund / exchange within first 5 days from the date of your purchase. If 5 days have passed since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become eligible for a return or an exchange:
        </p>
        <ul>
          <li>The purchased item should be unused and in the same condition as you received it.</li>
          <li>The item must have original packaging.</li>
          <li>If the item that you purchased on a sale, then the item may not be eligible for a return / exchange.</li>
        </ul>
        <p>
          Further, only such items are replaced by us (based on an exchange request), if such items are found defective or damaged.
        </p>
        <p>
          You agree that there may be a certain category of products / items that are exempted from returns or refunds. Such categories of the products would be identified to you at the item of purchase. For exchange / return accepted request(s) (as applicable), once your returned product / item is received and inspected by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further, if the same has been approved after the quality check at our end, your request (i.e., return / exchange) will be processed in accordance with our policies.
        </p>
      </section>

      <hr style={styles.divider} />

      {/* Shipping Policy */}
      <section style={styles.section}>
        <h2>Shipping Policy</h2>
        <p>
          The orders for the user are shipped through registered domestic courier companies and/or speed post only. Orders are shipped within 5 days from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable.
        </p>
      </section>
    </div> <br></br>
    <Footer></Footer>
    </>
  );
};

// Basic styling for the component
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  section: {
    marginBottom: '40px',
  },
  divider: {
    margin: '40px 0',
    border: 'none',
    borderTop: '1px solid #ccc',
  },
};

export default CancellationPolicy;
