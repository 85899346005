// src/TermsOfService.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image,Button } from 'react-bootstrap';
import '../../assets/css/cart.css';
import '../../assets/css/checkout.css';
import $ from 'jquery';
import axios from 'axios';
import Login from './Login';
import iconclose from '../../assets/images/hugeicons_cancel-circle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import StepProgressBar from './StepProgressbar';
import Header from "./Header";
import Footer from "./Footer";
const Checkout = () => {
  const steps = ['SignIn', 'Address', 'Payment'];
  const [currentStep, setCurrentStep] = useState(0);
  const [CartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [CartAmount, SetCartAmount]=useState('');
  const [data, Data] = useState([]); // Initial state
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cartId = params.get("CartId"); // Get the value for param1
  const totalprice = params.get("Amount"); // Get the value for param1
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  // Function to handle the "+" button
   
  return (<>
  <Header></Header>
  
  
  <div className="container progresscontiner mt-5">
    <center>
  <StepProgressBar steps={steps} currentStep={currentStep} /></center>
    <Row><Col md={12}>
      <div className='tab-1'>
      <Login></Login>
      </div>

      {/* <div className="mt-4">
        <button className="btn btn-primary" onClick={prevStep} disabled={currentStep === 0}>
          Previous
        </button>
        <button className="btn btn-primary ml-2" onClick={nextStep} disabled={currentStep === steps.length - 1}>
          Next
        </button>
      </div> */}
      </Col>
    </Row>
   
    </div>
   
    <Footer></Footer>
  </>)
};

export default Checkout;
