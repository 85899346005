// src/PrivacyPolicy.js
import React from "react";
import Header from "../Customer/Header";
import '../../assets/css/privacy.css';
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <br></br> <br></br>
    <div className="privacy-policy-container mt-5">
      <h1 className="privacy-policy-header">Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [22/09/2024]</p>

      <section className="privacy-section">
        <h2>1. Information We Collect</h2>
        <p>
          We collect information that you provide directly to us, as well as 
          automatically through your interaction with our website.
        </p>
        <h3>a) Personal Information</h3>
        <p>
          When you make a purchase, create an account, or contact us, we may collect 
          personal information such as: Full Name, Email Address, Shipping Address, 
          Phone Number, and Payment Details.
        </p>
        <h3>b) Automatically Collected Information</h3>
        <p>
          When you visit our site, we automatically collect data including: IP Address, 
          Browser Type, Device Information, Pages Viewed, and Time Spent on Pages.
        </p>
      </section>

      <section className="privacy-section">
        <h2>2. How We Use Your Information</h2>
        <p>
          We use the collected data for processing transactions, improving our services, 
          communicating with you, and complying with legal obligations.
        </p>
      </section>

      <section className="privacy-section">
        <h2>3. Data Security</h2>
        <p>
          We implement reasonable security measures to protect your data, but no 
          system is completely secure.
        </p>
      </section>

      <section className="privacy-section">
        <h2>4. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at: 
          care@rollerfashions.com.
        </p>
      </section>
    </div>
    <br></br>
    <Footer></Footer>
    </>
  );
};

export default PrivacyPolicy;
