import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../assets/css/ratesection.css';
import Top from '../../assets/images/Arrowtop.png';
import Bottom from '../../assets/images/Arrowbottom.png';


import { FaRegHeart } from "react-icons/fa";

const Ratesection = () => {
    return (
        <div className='padding maindiv'>
            <br>
            </br><br></br>
            <Container>
                <Image src={Top} className='arrtop'></Image>
                <Row>
                    <Col md={4}>
                        <Container>
                            <div className='leftdv art'>
                                <a href='../allproducts?Min=0&&Max=799'> <h2 className='top-categories-heading mt-2'><span className="thin">EVERYTHING</span><br></br><b>UNDER &#8377; 799</b> </h2>
                                </a></div>
                        </Container>
                    </Col>
                    <Col md={4} >
                        <Container>
                            <div className='middledv art'>
                                <a href='../allproducts?Min=0&&Max=999'> <h2 className='top-categories-heading mt-2'><span className="thin">EVERYTHING</span><br></br><b> UNDER &#8377; 999</b> </h2>
                                </a></div>
                        </Container>
                    </Col>
                    <Col md={4}>
                        <Container>
                            <div className='rightdv art'>
                                <a href='../allproducts?Min=0&&Max=1299'><h2 className='top-categories-heading mt-4'><span className="thin">EVERYTHING</span><br></br><b> UNDER &#8377; 1299</b> </h2>
                                </a></div>
                        </Container>

                    </Col>
                </Row>
                <Image src={Bottom} className='arrbottom'></Image>
            </Container>
        </div>
    );
}

export default Ratesection;
