import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/css/customerlogin.css";
import loginImage from '../../assets/images/10.png'; // Adjust path as needed
import { Link } from "react-router-dom";
import OtpForm from './OTP';
import $ from 'jquery';
import axios from 'axios';

function Login() {
    const [number, setNumber] = useState("");
    const [responseId, setResponseId] = useState('');
    const [responseCode, setresponseCode] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [otp, setOtp] = useState('');
    const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setNumber(onlyDigits);
    };

    const loginClick = () => {
        debugger;
        const formData = new FormData();
        formData.append('phone', number);
        const response = axios.post('https://api.rollerfashions.com/api/Authentication/Customer_login', formData, {
            headers: {
                'Content-Type': 'application/json'
            }

        })
            .then(response => {
                debugger;
                var getresponse = response.data;
                if (getresponse.responseCode == 1) {
                    setResponseId(getresponse.responseid);
                    sessionStorage.setItem('Sessionresponseid', getresponse.responseid);
                    setIsVisible(!isVisible);
                   

                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
        return false;
    };
    

      
    return (
        <>
            <Container fluid className="login-container">
                <Row className="justify-content-center align-items-center">
                    <Col md={5}>
                        <Image className='sculpture' src={loginImage}>
                        </Image>
                    </Col>
                    <Col md={6}>
                        <br>
                        </br><br></br>
                        {!isVisible && (
                        <div className='dvSignin'>
                            <center><h3>Login / Signup</h3></center>

                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Entire mobile number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        maxLength={10}
                                        placeholder="+91"
                                        value={number}
                                        onChange={(e) => checkInput(e)}
                                        required
                                    />
                                </Form.Group>
                                <br></br>
                                <br></br><br></br>
                                <Button variant="primary" onClick={loginClick} className="w-100 btn-primary p-3">
                                    Login
                                </Button>
                            </Form>
                        </div>
                        )}
                        {isVisible && (
                            <div id='dvotp' className='dvotp'>
                                <center><h3>Verify Mobile Number</h3></center>
                                <br>
                                </br>
                                <Form>
                                    <Form.Group controlId="OTP">
                                        <Form.Label>Sent the Verification Code to</Form.Label><b> {number}</b><br></br>

                                        {<OtpForm length={4} />}

                                    </Form.Group>
                                </Form>
                            

                            </div>
                        )}

                    </Col>
                </Row>
            </Container></>
    )
}

export default Login;

