// src/TermsOfService.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image,Button } from 'react-bootstrap';
import '../../assets/css/cart.css';
import $ from 'jquery';
import axios from 'axios';
import iconclose from '../../assets/images/hugeicons_cancel-circle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
const Cart = () => {
  const [CartCount, setCartCount] = useState(0);
  const [CartId, SetCartId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Cdata, setCData] = useState([]); // Initial state
  const [CartAmount, SetCartAmount]=useState('');
  const [data, Data] = useState([]); // Initial state
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const querystring = params.get("ProductId"); // Get the value for param1
  
  // Function to handle the "+" button
  function handleIncrease(event){
    debugger;
    let id= event.target.id;
    let item= $("#txt"+id).val();
    item = parseInt(item, 10);
    $("#txt"+id).val(item + 1); 
    item=item + 1; 
    let Id= id;
    const formData = new FormData();
    formData.append('id',Id);
    formData.append('quantity',item)
    formData.append('product_ID',querystring)
    const response = axios.post('https://api.rollerfashions.com/api/Cart/Update_cart', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
      fetchcart();
      fetchCartCount();
    })
    .catch(error => {
      console.error('Error:', error);
    })

  };

  // Function to handle the "-" button
  const handleDecrease = (event) => {
    
    let id= event.target.id;
    let item= $("#txt"+id).val();
    item = parseInt(item, 10);
   
    item=item - 1; 
    if(item>0){
      $("#txt"+id).val(item - 1); 
      let Id= id;
      const formData = new FormData();
      formData.append('id',Id);
      formData.append('quantity',item)
      formData.append('product_ID',querystring)
      const response = axios.post('https://api.rollerfashions.com/api/Cart/Update_cart', formData, {
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(response => {
        fetchcart();
        fetchCartCount();
      })
      .catch(error => {
        console.error('Error:', error);
      })
    }
    
  };
  const CloseCart = (event) => {
    
    $(".dvCart").hide();
    
  };
   const DeleteCartItem = (events) => {
    let Id= events.currentTarget.className;
    const formData = new FormData();
    debugger;
    if (Id != "") {
        formData.append('id',Id);
        const response = axios.post('https://api.rollerfashions.com/api/Cart/Delete_cart', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
          fetchcart();
          fetchCartCount();
          var getresponse = response.data;
        })
        .catch(error => {
          console.error('Error:', error);
        })
      
   
      }
    
  };

  // Function to handle changes in the input box directly
  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1) {
      $(e).val(value);
    }
  };
  useEffect(() => {
    fetchcart();
    fetchCartCount();
    

  }, []);
  const RedirectToCheckOut= ()=>{
   debugger;
    let SessionCartid = sessionStorage.getItem('sessioncart_Id');
    window.location="../checkout?CartId="+SessionCartid+"&Amount="+CartAmount;
  }
  const fetchCartCount = async () => {
    let SessionCartid = sessionStorage.getItem('sessioncart_Id');
    SetCartId(SessionCartid);
    if (SessionCartid != null) {
      fetch(`https://api.rollerfashions.com/api/Cart/Cart_count?Cart_ID=${SessionCartid}`) // Replace with your API endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })  
        .then((data) => {
          Data(data);
     
          setLoading(false);debugger;

        
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };


  const fetchcart = async () => {
    let SessionCartid = sessionStorage.getItem('sessioncart_Id');
    SetCartId(SessionCartid);
    if (SessionCartid != null) {
      fetch(`https://api.rollerfashions.com/api/Cart/List_cart?Cart_ID=${SessionCartid}`) // Replace with your API endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          
          setCData(data);
          setLoading(false);
          const totalSum = data.reduce((acc, CartItem) => acc + CartItem.product_Sellingprice, 0);
          debugger;
          SetCartAmount(totalSum);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }

  }; // 
  return (
<div className='dvCart'>
  <div className='dvCartInnr'>
    <Container>
      <Row>
        <Col md={9}>
          <label>BAG ({data.cartcount} items)</label>
        </Col>
<Col md={2}>
<Button className='btnCartClose' onClick={CloseCart}><Image src={iconclose}></Image></Button></Col>
      </Row>
      <br></br>
      {Cdata != null}
      {Cdata.map((CartItem) => (
        <>
        <br></br>
        <Row>
          <Col md={5}>
            <Image className="CartImage" src={CartItem.image}></Image>
          </Col>
          <Col md={7}>
            <h6 className='h6'>{CartItem.name}</h6>
            <label>SIZE : {CartItem.size}</label><br></br>
            <label>COLOUR : {CartItem.product_Colorname}</label>
            <table className='tablecart'>
              <tr>
                <td><div style={{ display: 'flex', alignItems: 'center' }}>
                  <button id={CartItem.id} className='btnsmall' onClick={handleDecrease}>-</button>

                  <input
                    id={'txt'+CartItem.id}
                    type="number"
                    value={CartItem.quantity}
                    readOnly='true'
                    onChange={handleChange}
                    className='txtqty'
                    min="1"
                  />
                  <button id={CartItem.id} className='btnsmalln' onClick={handleIncrease}>+</button>
                </div></td>
                <td><label>&#8377; {CartItem.product_Sellingprice}</label> </td><td><span onClick={DeleteCartItem} className={CartItem.id} id={CartItem.id}><FontAwesomeIcon icon={faTrash} className='faicon'></FontAwesomeIcon></span></td>
              </tr>
            </table>
            <br></br>
          </Col>
        </Row>
        </>
      ))}
    </Container>
    </div>
    <button className='btnLargeChk' onClick={RedirectToCheckOut}>CHECK OUT - &#8377; {CartAmount}/-</button></div>
  )
};

export default Cart;
